import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetUserByIdQuery } from "../../store/apiSlice";

export default function JobPostCredits({ employerDetails = {} }) {
  const userInfo = useSelector((state) => state.auth.userInfo);
  const { data } = useGetUserByIdQuery({ id: userInfo?.id });
  const jobPostCreditCount = useSelector((state) => state.posts.jobCredits);
  const expiryDate = userInfo?.unlimitedExpiry ? new Date(userInfo.unlimitedExpiry) : null;

  // Check if expiryDate is valid
  const isValidDate = expiryDate instanceof Date && !isNaN(expiryDate);

  return (
    <div className="w-full sm:max-w-[300px] bg-white p-6 rounded-lg shadow-lg">
      <div className="flex flex-col items-center gap-4">
        <div className="text-center">
          <span className="text-2xl font-bold text-amber-500">
            {jobPostCreditCount === -1 ? "Unlimited" : jobPostCreditCount}
          </span>
          <span className="ml-2 text-lg text-gray-700">
            {jobPostCreditCount === 1 ? " Job Credit" : " Job Credits"}
          </span>
        </div>

        {jobPostCreditCount !== -1 && (
          <Link to="/choose-job-pack">
            <button className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-6 rounded-lg shadow-md transition-all duration-300 transform hover:scale-105">
              Buy Credits
            </button>
          </Link>
        )}
      </div>

      {jobPostCreditCount === -1 && (
        <div className="mt-4 text-center">
          <p className="text-sm text-gray-500">
            <span className="font-semibold">Plan expires on: </span>
            {isValidDate ? (
              new Intl.DateTimeFormat('en-GB', { 
                day: 'numeric', 
                month: 'short', 
                year: 'numeric' 
              }).format(expiryDate)
            ) : (
              "No expiry date available"
            )}
          </p>
        </div>
      )}
    </div>
  );
}
