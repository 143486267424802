import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import RegionalPosting from './RegionalPosting';
import CreateInvoiceNicheJobBoardPayment from '../components/CreateInvoiceNicheJobBoardPayment';
import CountryList from '../data/CountryList.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const JobList1 = ({ endOfJobs, data, jobBoardData, nextPage }) => {
  const navigate = useNavigate();

  const userInfo = useSelector((state) => state.auth.userInfo);
  const jobPostCreditCount = useSelector((state) => state.posts.jobCredits);

  const [showDetails, setShowDetails] = useState({}); // Track toggle state for each job

  const toggleDetails = (id) => {
    setShowDetails((prevState) => ({
      ...prevState,
      [id]: !prevState[id], // Toggle the state for the specific job
    }));
  };

  const content = data.map((job, index) => {
    const {
      expiration_date,
      id,
      academic_connections_count,
      academic_connections // Assuming this contains the firstname and email domain
    } = job;


    return (
      <div
        className={`bg-white border mt-2 relative border-gray-200 p-4 mb-8 rounded-xl shadow-lg block font-bold`}
        key={index}
      >
        <div className="flex justify-between">
          <div className="flex items-center pb-2">
            <div className="flex-1">
              <span className="">
                <a
                  className="text-gray-500 text-xl mr-4 font-bold leading-tight hover:underline cursor-pointer"
                  href={`https://www.academicjobs.com/jobs/myjob/${job.id}`}
                  target="_blank"
                >
                  {job.title}
                </a>
              </span>
            </div>
          </div>
        </div>

        <div className="h-16"> {/* Add a height or ensure the parent has space for the content */}
          <div className="flex md:absolute md:left-4 md:bottom-4">
            Referral Count: {academic_connections_count}
            <button
              onClick={() => toggleDetails(id)}
              className="ml-2 text-blue-500 hover:underline"
            >
              {showDetails[id] ? (
                <FontAwesomeIcon icon={faChevronUp} />
              ) : (
                <FontAwesomeIcon icon={faChevronDown} />
              )}
            </button>
          </div>
          {showDetails[id] && (
            <div
              className="mt-2 bg-gray-100 border-2  rounded-lg z-50 absolute top-full left-0 w-full max-h-60 overflow-y-auto custom-scrollbar"
              style={{ zIndex: 1000 }}
            >
              {academic_connections && academic_connections.length > 0 ? (
                <table className="table-auto w-full text-sm text-left text-gray-600">
                  <thead className="bg-white sticky top-0">
                    <tr>
                      <th className="py-2 px-4">First Name</th>
                      <th className="py-2 px-4">Organisation Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {academic_connections.map((connection, idx) => (
                      <tr key={idx}>
                        <td className="py-2 px-4">{connection.firstname}</td>
                        <td className="py-2 px-4">{connection.email}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="text-sm text-gray-600">No connections available.</div>
              )}
            </div>
          )}


          <div className="flex md:absolute md:right-4 md:bottom-4">
            <div className="w-full sm:w-auto">
              <a
                className="btn text-amber-500 hover:underline text-xs cursor-pointer w-full sm:w-auto"
                href={`https://www.academicjobs.com/jobs/myjob/${job.id}?mode=share`}
                target="_blank"
              >
                Academic Connect
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4"> {/* Move grid container here */}
      {content}
    </div>
  );
};

export default JobList1;
