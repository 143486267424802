import React, { useState, useEffect } from "react";
import { useUpdatePostRegionsMutation } from "../store/apiSlice";

const RegionalPosting = ({ postRegions, id }) => {
  // Extract postRegion values to set as the initial state for selectedRegions
  const initialRegions = postRegions?.map(region => region.postRegion) || [];
  const [updatePostRegions] = useUpdatePostRegionsMutation();
  const [selectedRegions, setSelectedRegions] = useState(initialRegions);

  useEffect(() => {
    // Update selectedRegions when postRegions prop changes
    const updatedRegions = postRegions?.map(region => region.postRegion) || [];
    setSelectedRegions(updatedRegions);
  }, [postRegions]);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    let updatedRegions;
    if (checked) {
      updatedRegions = [...selectedRegions, value];
    } else {
      updatedRegions = selectedRegions.filter((region) => region !== value);
    }
    setSelectedRegions(updatedRegions);
    updatePostRegions({ id, postRegions: updatedRegions });
  };

  const options = [
    { value: "Australia", label: "Australia" },
    { value: "Canada", label: "Canada" },
    { value: "New Zealand", label: "New Zealand" },
    { value: "United Kingdom", label: "United Kingdom" },
    { value: "United States", label: "United States" },
  ];

  return (
    <div className="flex flex-col gap-0 mb-3">
      {options.map((option) => (
        <label key={option.value} className="flex items-center gap-2">
          <input
            type="checkbox"
            value={option.value}
            checked={selectedRegions.includes(option.value)}
            onChange={handleCheckboxChange}
          />
          {option.label}
        </label>
      ))}
    </div>
  );
};

export default RegionalPosting;
