import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useUpdateEmployerMutation } from "../store/apiSlice";
import InputBlock4 from "./InputBlock4";

const EmployerContactsForm = ({ employer }) => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const userInfo = useSelector((state) => state.auth.userInfo);

    const [
        updateEmployer,
        {
            isLoading: isLoadingUpdateEmployer,
            isSuccess: isSuccessUpdateEmployer,
            isError: isErrorUpdateEmployer,
            error: errorUpdateEmployer,
        },
    ] = useUpdateEmployerMutation();

    useEffect(() => {
        if (employer) {
            reset(employer);
        }
    }, [employer, reset]);

    const onSubmit = async (data) => {
        await updateEmployer({ id: employer?.id, ...data });
    };

    return (
        <form className="flex w-full flex-col gap-2" onSubmit={handleSubmit(onSubmit)}>
            <h2 className="underline mb-4 font-bold">Main Contact</h2>
            <div className="flex gap-4">
                <label className="w-[30%] text-right font-bold">First Name</label>
                <div className="w-[50%] text-left">
                    <InputBlock4
                        type="text"
                        field="firstname1"
                        label="firstname1"
                        register={register}
                        errors={errors}
                        forceClass=""
                    />
                </div>
            </div>
            <div className="flex gap-4">
                <label className="w-[30%] text-right font-bold">Last Name</label>
                <div className="w-[50%] text-left">
                    <InputBlock4
                        type="text"
                        field="lastname1"
                        label="lastname1"
                        register={register}
                        errors={errors}
                        forceClass=""
                    />
                </div>
            </div>
            <div className="flex gap-4">
                <label className="w-[30%] text-right font-bold">Job Title</label>
                <div className="w-[50%] text-left">
                    <InputBlock4
                        type="text"
                        field="jobtitle1"
                        label="jobtitle1"
                        register={register}
                        errors={errors}
                        forceClass=""
                    />
                </div>
            </div>
            <div className="flex gap-4">
                <label className="w-[30%] text-right font-bold">Email</label>
                <div className="w-[50%] text-left">
                    <InputBlock4
                        type="text"
                        field="email1"
                        label="email1"
                        register={register}
                        errors={errors}
                        forceClass=""
                    />
                </div>
            </div>
            <div className="flex gap-4">
                <label className="w-[30%] text-right font-bold">Phone Number</label>
                <div className="w-[50%] text-left">
                    <InputBlock4
                        type="text"
                        field="phone1"
                        label="phone1"
                        register={register}
                        errors={errors}
                        forceClass=""
                    />
                </div>
            </div>

            <h2 className="underline mt-6 mb-4 font-bold">Secondary Contact</h2>
            <div className="flex gap-4">
                <label className="w-[30%] text-right font-bold">First Name</label>
                <div className="w-[50%] text-left">
                    <InputBlock4
                        type="text"
                        field="firstname2"
                        label="firstname2"
                        register={register}
                        errors={errors}
                        forceClass=""
                    />
                </div>
            </div>
            <div className="flex gap-4">
                <label className="w-[30%] text-right font-bold">Last Name</label>
                <div className="w-[50%] text-left">
                    <InputBlock4
                        type="text"
                        field="lastname2"
                        label="lastname2"
                        register={register}
                        errors={errors}
                        forceClass=""
                    />
                </div>
            </div>
            <div className="flex gap-4">
                <label className="w-[30%] text-right font-bold">Job Title</label>
                <div className="w-[50%] text-left">
                    <InputBlock4
                        type="text"
                        field="jobtitle2"
                        label="jobtitle2"
                        register={register}
                        errors={errors}
                        forceClass=""
                    />
                </div>
            </div>
            <div className="flex gap-4">
                <label className="w-[30%] text-right font-bold">Email</label>
                <div className="w-[50%] text-left">
                    <InputBlock4
                        type="text"
                        field="email2"
                        label="email2"
                        register={register}
                        errors={errors}
                        forceClass=""
                    />
                </div>
            </div>
            <div className="flex gap-4">
                <label className="w-[30%] text-right font-bold">Phone Number</label>
                <div className="w-[50%] text-left">
                    <InputBlock4
                        type="text"
                        field="phone2"
                        label="phone2"
                        register={register}
                        errors={errors}
                        forceClass=""
                    />
                </div>
            </div>

            <button className='w-1/2 btn items-center m-auto'
                type="submit">
                Save Changes
            </button>
        </form>
    );
};

export default EmployerContactsForm;
