import React, { useState, useEffect, useRef, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import {
  useGetCampusesMutation,
  useGetEmployerSuggestionsQuery,
  usePostAJobMutation,
  useGetEmployerQuery,
  useUpdateEmployerMutation,
  useUpdateJobMutation,
  useGetJobByIdQuery,
  useDeductJobCreditsMutation
} from "../store/apiSlice";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useLocation } from "react-router-dom";
import InputBlock from "../components/ui/InputPostAJob";
import InputBlock2 from "./InputBlock2";
import SelectBlock2 from "./SelectBlock2";
import SelectBlock from "./SelectBlock";
import UniSearchBlock from "./UniSearchBlock";
import SelectBlockNew from "./SelectBlockNew";
import SelectBlock1 from "./SelectBlock1";
import job_category from "../data/JobCategories1.json";
import { countries } from "../utils/data";
import { currencies } from "../utils/data";
import { Link, NavLink } from "react-router-dom";
//import { setemployer.jobCredits } from "./postSlice"
import { regions } from "../utils/data";
import { setJob, setId, setEmployer } from "../store/postsSlice";
import { useSendEmail3Mutation } from "../store/apiSlice";
import { BsCheckLg } from "react-icons/bs";
import AppContextTitleAndQuote from "../components/profile/AppContextTitleAndQuote";
import PricingTable from "../components/PricingTable";
import JobKeywordSearchBlock from "../views/JobKeywordSearchBlock";
import axios from 'axios';

const validationSchema = yup
  .object({
    // Your validation schema
  })
  .required();

const academic_positions = {
  "Faculty Positions": [
    "Lecturer/Instructor",
    "Senior Lecturer",
    "Sessional Lecturer",
    "Tutor",
    "Teaching Assistant",
    "Adjunct Faculty",
    "Post-Doc",
    "Tenure",
    "Tenure-track",
  ],
  "Research Positions": [
    "Research",
    "Scientist/Associate",
    "Senior Research Assistant",
    "Research Assistant",
    "Research Fellow",
    "Post Doc Research Fellow",
    "Research Coordinator",
    "Research Manager",
    "Research Technician",
    "Faculty Researcher",
    "PhD Researcher",
  ],
  "Professorial Positions": [
    "Professor",
    "Assistant Professor",
    "Associate Professor",
    "Senior Professor",
    "Adjunct Professor",
    "Visiting Professor",
    "Research Professor",
    "Clinical Professor/Prof of Practice",
    "Emeritus Professor",
    "Visiting Scholar",
    "Visiting Fellow",
  ],
  "Executive Positions": [
    "Assistant/Associate Head",
    "Dean",
    "Assistant/Associate Dean",
    "Director/Manager",
    "Assistant Director",
    "Dept. Head/Head/Chair",
    "Endowed",
    "Program Coordinator",
    "Clinical Chair",
  ],
  Others: [
    "Sessional Academic",
    "Clinical Educator",
    "Academic Advisor",
    "Curriculum Developer",
    "Educational Technologist",
    "Learning Specialist",
    "Academic Coach",
  ],
  Others1: [
    "Lab Manager",
    "Program Director",
    "Academic Coordinator",
    "Exam Supervisor",
    "Librarian",
    "Faculty Development Specialist",
    "Instructional Designer",
  ],
};

const JobAddEdit_Employer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const job = location.state?.job || null;
  const userInfo = useSelector((state) => state.auth.userInfo);
  const jobPostCreditCount = useSelector((state) => state.posts.jobCredits);

  const isAddMode = !job || Object.keys(job).length === 0;

  const [secondCategory, setSecondCategory] = useState(job?.thirdcategory ?? null);

  let defaultValues;
  let buttonText;
  const {
    control,
    register,
    reset,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
  });

  let master_category_job_type = watch("master_category_job_type");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!job) {
      buttonText = "Post Job";
      reset({
        salary_period: "yearly",
        currency: "USD",
        activation_date: new Date().toISOString().substring(0, 10),
        expiration_date: new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000).toISOString().substring(0, 10),
        remote: "Onsite",
        position_type: "",
      });
      if (userInfo?.jobCredits == -1) {
        setValue("featured", true);
      }
      master_category_job_type = "";
      setValue("master_category_job_type", "");
    } else {
      buttonText = "Update Job";
      reset(job);
      master_category_job_type = job.master_category_job_type;
      setValue("activation_date", new Date(job.activation_date).toISOString().split("T")[0]);
      setValue("expiration_date", new Date(job.expiration_date).toISOString().split("T")[0]);
      dispatch(
        setEmployer({
          company_name: job?.company_name,
          employer_id: job.employer_id,
          logo: job.logo,
          // employerPageURL: job.employer?.employerPageURL,
          clientType: job.clientType,
        })
      );
    }
  }, [job]);

  useEffect(() => {
    register("description", { required: false });
    register("company_name1", { required: false });
  }, [register]);

  useEffect(() => {
    if (!job) {
      setValue("company_name1", userInfo?.company_name);
      setValue("employer_id", userInfo?.employer_id);
      setValue("headlineOnly", userInfo?.jobCredits === 0);
    }
  }, [userInfo]);

  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyCKEfoOIPz8l_6A8BByD3b3-ncwza8TNiA", //process.env.REACT_APP_GOOGLE,
    options: {
      componentRestrictions: { country: "Australia" },
    },
    onPlaceSelected: (place) => {
      const addressComponents = place.address_components || [];
      let city = "";
      let state = "";
      let country1 = "";
      let postalCode = "";
      addressComponents.forEach((component) => {
        const types = component.types || [];
        if (types.includes("locality")) {
          city = component.long_name;
        } else if (types.includes("administrative_area_level_1")) {
          state = component.long_name;
        } else if (types.includes("country")) {
          country1 = component.long_name;
        } else if (types.includes("postal_code")) {
          postalCode = component.long_name;
        }
      });

      setValue("location", place.formatted_address);
      setValue("city", city);
      setValue("state", state);
      setValue("country", country1);
      setValue("region", country1);
      setValue("postalCode", postalCode);
    },
  });

  const jobStatusDefault = job ? "Update Job" : (userInfo.employer_id !== undefined && userInfo.jobCredits === 0 ? "Save, Pay & Post" : "Post");
  const [jobStatus, setJobStatus] = useState(jobStatusDefault);
  const editorContent = watch("description");

  const [query, setQuery] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(true);
  const inputRef = useRef();
  const submitRef = useRef();
  const countryRef = useRef("");
  const { data: suggestions = [] } = useGetEmployerSuggestionsQuery({ query, country: countryRef.current || "" }, {});
  const [updateJob, { isLoading: isLoadingUpdateJob, isSuccess: isSuccessUpdateJob, isError: isErrorUpdateJob, error: errorUpdateJob }] = useUpdateJobMutation();
  const [postAJob, { data: dataPostAJob, isLoading: isLoadingPostAJob, isSuccess: isSuccessPostAJob, isError: isErrorPostAJob, error: errorPostAJob }] = usePostAJobMutation();
  const [deductJobCredit, { data: dataJobCredits, isLoading: isLoadingJobCredits }] = useDeductJobCreditsMutation();

  const [newList, setNewList] = useState([]);
  const [newListName, setNewListName] = useState(null);
  const [newList1, setNewList1] = useState([]);
  const [newList1Name, setNewList1Name] = useState(null);
  const userid = useSelector((state) => state.auth.userid);
  const country = useSelector((state) => state.auth.country);
  const company_name = useSelector((state) => state.auth.company_name);
  const navigate = useNavigate();
  const job_type = ["Full time", "Part time", "Contractor", "Sessional"];

  const onEditorStateChange = (editorState) => {
    setValue("description", editorState);
  };

  const onEditorStateChange1 = (company_name, employer_id, Region, country, logo, employerPageURL, clientType) => {
    setValue("company_name1", company_name);
    setValue("employer_id", employer_id || 0);
    setValue("Region", Region);
    setValue("country", country);
    setValue("logo", logo);
    dispatch(setEmployer({ company_name, employer_id, logo, employerPageURL, clientType }));
    if (employer_id) getCampuses({ id: employer_id });
  };

  function findValueForKey(array, keyToFind) {
    for (const obj of array) {
      const keys = Object.keys(obj);
      if (keys.includes(keyToFind)) {
        return obj[keyToFind];
      }
    }
    return null;
  }

  const [getCampuses, { data: dataCampuses }] = useGetCampusesMutation();
  let content, content1, content2;
  const recordRef = useRef({ employer_id: userid });
  const handleChange1 = (newValue) => {
    setNewList(findValueForKey(job_category, newValue));
    setNewListName(newValue);
    setNewList1([]);
    setNewList1Name(null);
  };

  const handleChange2 = (newValue, flag) => {
    recordRef.current = { ...recordRef.current, [flag]: newValue };
    setNewList1(findValueForKey(job_category, newValue));
    setNewList1Name(newValue);
  };

  const handleChange3 = (newValue, flag) => {
    recordRef.current = { ...recordRef.current, [flag]: newValue };
  };

  const onSubmit = async (data) => {
    console.log("Form data:", data); // Debugging
    return isAddMode ? createJob(data) : updateJob1({ id: job?.id, ...data });
  };

  async function createJob(data) {
    setJobStatus("Job Posting ......!");

    // Determine the value for headlineOnly based on user job credits
    const headlineOnly = jobPostCreditCount === 0;

    // Create the job with the appropriate headlineOnly parameter
    const {
      data: { id },
    } = await postAJob({
      ...data,
      postedBy: userInfo.id,
      headlineOnly: headlineOnly,
    });

    setJobStatus("Job POSTED!");

    // Deduct job credits if the user has more than 0 credits
    if (jobPostCreditCount > 0) {
      await deductJobCredit({ userId: userInfo.id });
    }
  }


  async function updateJob1(data) {
    setJobStatus("Updating Job...");
    const response = await updateJob(data);
    if (response) {
      setJobStatus("All Done!");
      setTimeout(() => {
        setJobStatus("Update Job");
      }, 10000);
    }
  }

  content = (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full grid grid-cols-1 gap-8 sm:grid-cols-2">
        <div className="w-full flex flex-col gap-2">
          <div className="flex justify-start gap-4">
            <ul className=" flex justify-start items-end gap-4 px-1">
              <div className="card bg-base-100 border border-1 p-2 border-slate-300 w-[100px] h-[100px] grid place-items-center">
                <img
                  src={
                    userInfo?.logo
                      ? `https://academicjobs.s3.amazonaws.com/img/university-logo/${userInfo?.logo}`
                      : "/favicon.png"
                  }
                  alt={`${company_name}`}
                  className="object-contain rounded-md bg-white"
                />
              </div>
            </ul>
            <div className=" w-[100%]">
              <div className="w-[100%] mx-auto flex flex-col gap-1">
                <label className="label-text font-bold pb-2 text-lg">Institution Name</label>

                {userInfo?.employer_id === undefined ? (
                  <UniSearchBlock
                    register={register}
                    field="employer_name"
                    customKey="employer_name"
                    label="employer_name"
                    value1={userInfo?.company_name}
                    forceClass="mb-6"
                    onChange={onEditorStateChange1}
                  />
                ) : (
                  <>
                    {userInfo?.company_name && (
                      <label className="label-text font-black pb-2 text-blue-800 text-xl">{userInfo?.company_name}</label>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <label className="label-text  text-lg mt-4 font-bold">Job Title</label>
          <InputBlock2 type="text" field="title" label="" register={register} errors={errors} forceClass="" />
          <div className="flex gap-2 items-center justify-between">
            {/* <div className="form-control">
              <label className="cursor-pointer label">
                <input
                  type="checkbox"
                  {...register("featured")}
                  defaultChecked
                  className="checkbox checkbox-warning checkbox-sm mr-3"
                />
                <span className="label-text">
                  {userInfo.employer_id === undefined ? "Featured" : "Make this a Featured Job for an extra $50"}
                </span>
              </label>
            </div> */}
            {userInfo.region == 'Australia' && 
            <div className="form-control">
              <label className="cursor-pointer label">
                <input
                  type="checkbox"
                  {...register("indigenousJobYN")}
                  className="checkbox checkbox-warning checkbox-sm mr-3"
                />

                <span className="label-text">
                  Indigenous Job
                </span>
                <figure>
                  <img
                    src={'/australian-aboriginal-flag.png'}
                    alt="Indigenous Australian Aboriginal Flag"
                    width={25}
                    height={25}
                    className="ml-2 max-w-7"
                  />
                </figure>
                <figure>
                  <img
                    src={'/australia-torres-streight-islanders.svg'}
                    alt="Indigenous Australian Aboriginal Flag"
                    width={25}
                    height={25}
                    className="ml-2 max-w-7"
                  />
                </figure>
                <figure>
                  <img
                    src={'/Flag-of-Maori.png'}
                    alt="Maori Flag"
                    width={25}
                    height={25}
                    className="ml-2 max-w-7"
                  />
                </figure>
              </label>
            </div>}
            {userInfo.employer_id === undefined ? (
              <>
                <div className=" w-[100%] mx-auto flex justify-start items-start  gap-1 ">
                  <input type="checkbox" {...register("internalonly")} />
                  <label className="label-text text-sm">Hide Job</label>
                </div>
                <div className=" w-[100%] mx-auto flex justify-start items-start  gap-1 ">
                  <input type="checkbox" {...register("headlineOnly")} />
                  <label className="label-text text-sm">Headline Only</label>
                </div>
              </>
            ) : null}
          </div>

          <div className="mb-6 mt-4">
            <label className="label-text pb-2 text-lg mt-4 font-bold">Job Description</label>
            <ReactQuill className="quill job_post_editor.quill min-h-500px sm:min-h-200px" value={editorContent} onChange={onEditorStateChange} />
          </div>
        </div>
        <div className="w-full flex flex-col">
          <div className="py-[2rem] text-4xl font-black text-yellow-500 flex flex-col gap-2 "></div>
          <label className="label-text font-bold text-lg">Job Specialty</label>
          <JobKeywordSearchBlock
            customKey="Enter a keyword"
            label ="Enter Keyword and select it from the drop-down list"
            value1={secondCategory}
            forceClass="mb-8"
            required={true}
            onSelect={(realCtg, category2, category01, category01A, category02, category02A) => {
              console.log(category01, category01A);
              console.log(realCtg, category2);
              setValue(realCtg, category2);
              if (category01) setValue(category01, category01A);
              if (category02) setValue(category02, category02A);
              if (realCtg == "thirdcategory") setSecondCategory(category02A);
            }}
            className="w-full"
          />
          <SelectBlock2 list={job_type} field="job_type" label="Employment Type" register={register} errors={errors} forceClass="mt-4 font-bold" />
          <div className="label">
            <span className="label-text pb-2 text-lg font-bold mb-[-10px] mt-4">Job Location</span>
          </div>
          <div>
            <Autocomplete
              className="border p-1 rounded-lg mt-2 text-pink-400"
              style={{ width: "100%" }}
              apiKey="AIzaSyCKEfoOIPz8l_6A8BByD3b3-ncwza8TNiA"
              {...register("location1")}
              onPlaceSelected={(place) => {
                const addressComponents = place.address_components || [];
                let city = "";
                let state = "";
                let country1 = "";
                let postalCode = "";
                addressComponents.forEach((component) => {
                  const types = component.types || [];
                  if (types.includes("locality")) {
                    city = component.long_name;
                  } else if (types.includes("administrative_area_level_1")) {
                    state = component.long_name;
                  } else if (types.includes("country")) {
                    country1 = component.long_name;
                  } else if (types.includes("postal_code")) {
                    postalCode = component.long_name;
                  }
                });

                setValue("location", place.formatted_address);
                setValue("city", city);
                setValue("state", state);
                setValue("country", country1);
                setValue("region", country1);
                setValue("postalCode", postalCode);
              }}
              options={{
                types: ["geocode", "establishment"],
              }}
              onChange={(e) => setValue("location", e.target.value)}
            />
            <div>{job?.location}</div>
            <div className="flex items-center justify-start mt-4">
              <div className="form-control items-start mb-2 mr-4">
                <label className="flex items-start justify-start label cursor-pointer">
                  <input {...register("remote")} type="radio" className="radio radio-warning radio-xs mr-1" value="Onsite" />
                  <span className="label-text font-bold pb-2 text-sm">Onsite</span>
                </label>
              </div>
              <div className="form-control items-start mb-2 mr-4">
                <label className="flex items-start justify-start label cursor-pointer">
                  <input {...register("remote")} type="radio" className="radio radio-warning radio-xs mr-1" value="Remote" />
                  <span className="label-text font-bold pb-2 text-sm">Remote</span>
                </label>
              </div>
              <div className="form-control items-start mb-2">
                <label className="flex items-start justify-start label cursor-pointer">
                  <input {...register("remote")} type="radio" className="radio radio-warning radio-xs mr-1" value="Hybrid" />
                  <span className="label-text font-bold pb-2 text-sm">Hybrid</span>
                </label>
              </div>
            </div>
          </div>
          <div className="label">
            <span className="label-text pb-2 text-lg font-bold mb-[-10px]">Salary Range</span>
          </div>
          <div className="join flex mb-6 gap-2">
            <div className="max-w-[80px]">
              <div>
                <SelectBlock2
                  list={currencies}
                  field="currency"
                  label="Currency"
                  register={register}
                  errors={errors}
                  forceClass="join-item rounded-r-none min-h-[34px]"
                />
              </div>
            </div>
            <div className="flex-grow">
              <InputBlock2 type="text" field="salary_from" label="$From" register={register} errors={errors} forceClass="rounded-none min-h-[34px]" />
            </div>
            <div className="flex-grow">
              <InputBlock2 type="text" field="salary_to" label="$To" register={register} errors={errors} forceClass="rounded-none min-h-[34px]" />
            </div>
            <div className="max-w-[100px]">
              <div>
                <SelectBlock2
                  list={["yearly", "monthly", "weekly", "daily", "hourly"]}
                  field="frequency"
                  label="Frequency"
                  register={register}
                  errors={errors}
                  forceClass="join-item rounded-l-none min-h-[34px]"
                />
              </div>
            </div>
          </div>
          <label className="label-text font-bold pb-2 text-lg">How To Apply</label>
          <InputBlock2 type="text" field="how_to_apply" label="" register={register} errors={errors} forceClass="" />
          <div className="grid grid-cols-2 gap-4 mb-4 mt-8">
            <InputBlock2
              type="date"
              field="activation_date"
              label="Post Date"
              register={register}
              errors={errors}
              forceClass="mb-6 font-bold text-xl"
            />
            <InputBlock2
              type="date"
              field="expiration_date"
              label="Expiration Date"
              register={register}
              errors={errors}
              forceClass="mb-6 font-bold"
            />
          </div>
          {!userInfo.employer_id && (
            <>
              <div className="flex flex-col gap-2 mb-8">
                <p>
                  <span className="font-bold text-xl">Auto-Sales Info</span> <br />
                  Email Addresses and information found in this job description:
                </p>
                <div className="flex gap-2 justify-start">
                  <InputBlock2 type="text" field="firstName1" placeholder="First Name" label="" register={register} errors={errors} forceClass="" forceClass1="bg-yellow-100 text-gray-800" />
                  <InputBlock2 type="text" field="lastName1" placeholder="Last Name" label="" register={register} errors={errors} forceClass="" forceClass1="bg-yellow-100 text-gray-800" />
                  <InputBlock2 type="text" field="jobTitle1" placeholder="Job Title" label="" register={register} errors={errors} forceClass="" forceClass1="bg-yellow-100 text-gray-800" />
                  <InputBlock2 type="text" field="email1" placeholder="Email" label="" register={register} errors={errors} forceClass="" forceClass1="bg-yellow-100 text-gray-800" />
                  <InputBlock2 type="text" field="phone1" placeholder="Phone" label="" register={register} errors={errors} forceClass="" forceClass1="bg-yellow-100 text-gray-800" />
                </div>
                <div className="flex gap-2 justify-start">
                  <InputBlock2 type="text" field="firstName2" placeholder="First Name" label="" register={register} errors={errors} forceClass="" forceClass1="bg-yellow-100 text-gray-800" />
                  <InputBlock2 type="text" field="lastName2" placeholder="Last Name" label="" register={register} errors={errors} forceClass="" forceClass1="bg-yellow-100 text-gray-800" />
                  <InputBlock2 type="text" field="jobTitle2" placeholder="Job Title" label="" register={register} errors={errors} forceClass="" forceClass1="bg-yellow-100 text-gray-800" />
                  <InputBlock2 type="text" field="email2" placeholder="Email" label="" register={register} errors={errors} forceClass="" forceClass1="bg-yellow-100 text-gray-800" />
                  <InputBlock2 type="text" field="phone2" placeholder="Phone" label="" register={register} errors={errors} forceClass="" forceClass1="bg-yellow-100 text-gray-800" />
                </div>
              </div>
              <div>
                <label className="label-text font-bold text-lg">Regions to post this job:</label>
                <div className="flex flex-wrap gap-4">
                  {[
                    "Global",
                    "Australia",
                    "Asia",
                    "Africa",
                    "Canada",
                    "Europe",
                    "India",
                    "South America",
                    "Middle East",
                    "New Zealand",
                    "United Kingdom",
                    "United States",
                  ].map((region, i) => (
                    <div className="flex gap-1" key={region}>
                      <div className=" text-left">
                        <input type="checkbox" value={region} defaultChecked={job?.postRegions?.some((p) => p.postRegion === region)} {...register(`postRegions[${i}].postRegion`)} />
                      </div>
                      <label className={`text-sm text-left text-gray-600`}>{region}</label>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
          <div className="flex justify-start gap-1">
            <button type="submit" className="btn btn-success" ref={submitRef}>
              {jobStatus}
            </button>
            {content2}
          </div>
        </div>
      </form>
      {content1}
    </>
  );
  return <div className="overflow-y w-full mt-8">{content}</div>;
};

export default JobAddEdit_Employer;

