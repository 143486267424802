import React, { useState } from 'react'
import { Link } from "react-router-dom";

const JobList1 = ({ endOfJobs, data, nextPage }) => {
  console.log(data)
  const [isOpen, setIsOpen] = useState(false)
  const [filterAcademicLeads, setFilterAcademicLeads] = useState(false)

  // Filter jobs based on academicLeads
  const filteredData = filterAcademicLeads
    ? data.filter(job => job.academicLeads == true)
    : data;

  const content = filteredData.map((job, index) => {
    const { headlineOnly, email1, activation_date, activeYN, title, location, expiration_date, featured, id, academicLeads, academic_connections_count, company_name } = job;
    return (
      <div className={`font-bold ${index % 2 === 0 ? '' : ''}`} key={index}>
        <Link className="bg-white border border-gray-200 p-4 mb-4 rounded-xl shadow-lg block" to={`/jobs/edit/${id}/`}>
          <div className="flex items-center pb-2">
            <div className="flex-1">
              <span className="block text-gray-500 text-xl font-bold leading-tight hover:underline cursor-pointer">
                {title}
              </span>
            </div>
            {academicLeads ?
            (<a
              className="btn text-amber-500 hover:underline text-xs cursor-pointer w-full sm:w-auto"
              href={`https://www.academicjobs.com/jobs/myjob/${id}?mode=share`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(event) => event.stopPropagation()}
            >
              Academic Connect
            </a>) : (null)}
          </div>
          <div className="flex justify-between items-center">
            <div className="text-sm text-gray-700 font-light">{company_name}</div>
            <div className="text-sm text-gray-700 font-light">{location}</div>
          </div>
          <div className="flex justify-between items-center">
            <div className="text-sm text-gray-700 font-light">
              {new Date(activation_date).toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
              })}
            </div>
            <div className="w-[300px] text-sm text-amber-500 font-light">
              {featured ? <span>Featured</span> : <span>Not Featured</span>}
            </div>
            <div className="w-[300px] text-sm font-light">
              Academic Connection count: <span className={job.academic_connections_count === 0 ? "text-red-600" : "text-green-500"}>
                {job.academic_connections_count || 0}
              </span>
            </div>
            <div className="text-sm text-gray-700 font-light">
              {headlineOnly ? <span className="text-green-500">Headline Only</span> : <span>Full Job</span>}
            </div>
            <div className="text-sm text-gray-700 font-light">
              {email1 && <span className="text-red-600 font-bold">Sales Leads</span>}
            </div>
            <div className="text-sm text-gray-700 font-light">
              {activeYN ? <span className="text-green-500">Active</span> : <span>Not Active</span>}
            </div>

            <div className="text-gray-700 text-sm font-light">
              {new Date(expiration_date) < new Date() && expiration_date ? (
                <div className="bg-opacity-50 bg-red-500">Expired</div>
              ) : (
                <time>
                  {expiration_date && `Exp. ${new Date(expiration_date).toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric',
                  })}`}
                </time>
              )}
            </div>
          </div>
        </Link>
      </div>
    );
  });

  return (
    <div className="overflow-y w-full">
      {/* Academic Leads Filter */}
      <div className="mb-4">
        <label className="text-sm font-medium text-gray-700 mr-2">Filter by Academic Leads:</label>
        <input
          type="checkbox"
          checked={filterAcademicLeads}
          onChange={() => setFilterAcademicLeads(!filterAcademicLeads)}
          className="cursor-pointer"
        />
      </div>

      {content}

      <button
        type="button"
        className="load-more bg-white py-2 px-4 border border-gray-300 rounded-md text-black hover:bg-gray-100"
        onClick={() => { if (!endOfJobs) { nextPage() } }}
      >
        {endOfJobs ? 'End Of Job list' : 'Load more'}
      </button>
    </div>
  );
}

export default JobList1;
