import React, { useState } from 'react';
import {
  useGetOtherContactsQuery,
} from "../store/apiSlice";
import AddOtherContacts from './AddOtherContacts';
import OtherContactsRowEdit from './OtherContactsRowEdit';
import OtherContactsRowEditQuickCheck from './OtherContactsRowEditQuickCheck';
import MainSecondaryContactEdit from "./MainSecondaryContactEdit";

const OtherContacts = ({ id, employer }) => {
  const { data, isLoading, isSuccess, isError } = useGetOtherContactsQuery({ id });
  const [isExpanded, setIsExpanded] = useState(true); // State to track whether the list is expanded
  const [selectedContact, setSelectedContact] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filter, setFilter] = useState('All');

  const toggleExpand = () => {
    setIsExpanded(!isExpanded); // Toggle the expanded state
  };

  const handleEditClick = (contact) => {
    setSelectedContact(contact);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedContact(null);
    setIsModalOpen(false);
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const copyToClipboard = () => {
    // Convert filteredData to CSV format with proper escaping for Excel
    const csvData = filteredData.map(contact => (
      `"${contact.firstName}","${contact.lastName}","${contact.title}","${contact.email}","${contact.phone}"`
    ));
  
    // Add headers to CSV
    const csvContent = [
      '"First Name","Last Name","Title","Email","Phone"', // CSV header
      ...csvData // CSV rows
    ].join('\n'); // Join rows with newline characters
  
    // Copy the CSV content to the clipboard
    navigator.clipboard.writeText(csvContent).then(() => {
      alert('Data copied to clipboard as CSV!');
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  };
  

  // Ensure `data` is checked before filtering to avoid errors
  const filteredData = data?.filter(contact => {
    if (filter === 'HR') return contact.hrYN === 1 && contact.recruiterYN === 0;
    if (filter === 'Recruitment') return contact.recruiterYN === 1;
    if (filter === 'HR & Recruitment') return contact.recruiterYN === 1 && contact.hrYN === 1;
    if (filter === 'Department Lead') return contact.recruiterYN === 0 && contact.hrYN === 0 && contact.departmentLeadYN === 1;
    if (filter === 'Not Assigned') return contact.hrYN === 0 && contact.recruiterYN === 0 && contact.departmentLeadYN === 0;
    return true; // 'All' or any other filter shows all contacts
  }) || []; // Fallback to an empty array if `data` is undefined

  if (isLoading) {
    return <p>Loading...</p>;
  }
  if (isError) {
    return <p>No contacts found...</p>;
  }

  return (
    <div
      className={`overflow-y-auto ${isExpanded
          ? "fixed top-0 right-0 h-full w-1/2 z-50 bg-white p-4"
          : "w-full mb-8"
        } transition-all duration-300`}
    >
      <button
        onClick={toggleExpand}
        className="bg-blue-500 text-white px-4 py-2 mb-4 rounded"
      >
        {isExpanded ? "Collapse" : "Expand"}
      </button>
      <MainSecondaryContactEdit employer={employer} />
      <h2 className='font-bold underline pt-2'>OTHER CONTACTS</h2>
      <AddOtherContacts id={id} />

      <div className="flex justify-between items-center p-4">
        <div className="flex gap-4">
          <button className={`btn btn-xs ${filter === 'All' ? 'btn-primary' : ''}`} onClick={() => handleFilterChange('All')}>All</button>
          <button className={`btn btn-xs ${filter === 'HR' ? 'btn-primary' : ''}`} onClick={() => handleFilterChange('HR')}>HR</button>
          <button className={`btn btn-xs ${filter === 'Recruitment' ? 'btn-primary' : ''}`} onClick={() => handleFilterChange('Recruitment')}>Recruitment</button>
          <button className={`btn btn-xs ${filter === 'HR & Recruitment' ? 'btn-primary' : ''}`} onClick={() => handleFilterChange('HR & Recruitment')}>HR & Recruitment</button>
          <button className={`btn btn-xs ${filter === 'Department Lead' ? 'btn-primary' : ''}`} onClick={() => handleFilterChange('Department Lead')}>Department Lead</button>
          <button className={`btn btn-xs ${filter === 'Not Assigned' ? 'btn-primary' : ''}`} onClick={() => handleFilterChange('Not Assigned')}>Not Assigned</button>
        </div>
        <button className="btn btn-xs bg-green-500 text-white" onClick={copyToClipboard}>
          Copy to Clipboard
        </button>
      </div>

      {isSuccess && filteredData.length > 0 ? (
        <div>
          <ul className="list-none p-4">
            {filteredData.map((contact) => (
              <li key={contact.id} className="flex justify-between items-center p-2 border-b">
                <div>
                  <p>{contact.firstName} {contact.lastName} ({contact.title})</p>
                  <p className="text-sm text-gray-500">{contact.email}</p>
                  <p className="text-sm text-gray-500">{contact.phone}</p>
                </div>
                <OtherContactsRowEditQuickCheck contact={contact} />
                <button
                  className="btn btn-xs"
                  onClick={() => handleEditClick(contact)}
                >
                  Edit
                </button>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <p>No contacts available to display.</p>
      )}

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 bg-black opacity-50" onClick={handleCloseModal}></div>
          <div className="bg-white p-6 rounded-lg shadow-lg z-10">
            <OtherContactsRowEdit contact={selectedContact} onClose={handleCloseModal} />
          </div>
        </div>
      )}
    </div>
  );
};

export default OtherContacts;
