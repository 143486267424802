import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useUpdateContactMutation } from "../store/apiSlice";

const OtherContactsRowEdit = ({ contact }) => {
  const [updateContact] = useUpdateContactMutation();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset(contact);
  }, [contact, reset]);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    await updateContact({ id: contact?.id, ...data });
  };

  return (
    <div className="flex">
      <form className="flex gap-4 items-center" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex items-center gap-2">
          <label>
            <span>HR</span>
            <input type="checkbox" {...register("hrYN")} />
          </label>
        </div>
        <div className="flex items-center gap-2">
          <label>
            <span>Recruiter</span>
            <input type="checkbox" {...register("recruiterYN")} />
          </label>
        </div>
        <div className="flex items-center gap-2">
          <label>
            <span>Department Lead</span>
            <input type="checkbox" {...register("departmentLeadYN")} />
          </label>
        </div>
        <div className="flex justify-end ml-auto">
          <button type="submit" className="btn btn-xs">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default OtherContactsRowEdit;
