import { useSelector, useDispatch } from "react-redux";
import React, { useState } from "react";
import SearchResults2A from "./SearchResults2A";
import SearchResultsAcademicConnect from "./SearchResultsAcademicConnect"; // Import the new component
import { useNavigate, useLocation } from "react-router-dom"; // Import useLocation
import { useForm } from "react-hook-form";

function JobsCenter(props) {
  const navigate = useNavigate();
  const location = useLocation(); // Use the location hook
  const dispatch = useDispatch();
  const region = useSelector((state) => state.posts.region);
  const userInfo = useSelector((state) => state.auth.userInfo);
  const [q, setQ] = useState({
    userId: -1,
    employer_id: userInfo?.employer_id || -1,
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onEditorStateChange1 = (company_name, id, Region, country) => {
    setValue("employer_name", company_name);
  };

  const onSubmit = async (data) => {
    console.log("data", data);
    setQ(data);
  };

  // Determine which component to render based on the current route
  const SearchResultsComponent = location.pathname === "/academic-connect" 
    ? SearchResultsAcademicConnect 
    : SearchResults2A;

  return (
    <div className="flex flex-col px-6 gap-8 mt-8">
      <SearchResultsComponent q={q} />
    </div>
  );
}

export default JobsCenter;
