import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, Link, NavLink } from "react-router-dom";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { setJobCredits } from "../store/postsSlice";
import UniSearchBlock from "./UniSearchBlock";
import { useForm } from "react-hook-form";
import LowJobCountEmlpoyers from "../components/LowJobCountEmlpoyers";
import { setJob, setId, setEmployer } from '../store/postsSlice'
import { useGetUserByIdQuery, usegetAUNZEmployers } from "../store/apiSlice"
import { useGetJobPaddingEmployersQuery } from "../store/apiSlice"
import { useGetAUNZEmployersQuery } from "../store/apiSlice"
import TopUniversityPadding from "../components/TopUniversityPadding";
import SearchResults1 from "./SearchResults1";
import AusNzEmployers from "../components/AUNZEmployers";
import UnlimitedClients from "../components/UnlimitedClients";
import GoogleSearchLinks from "../components/GoogleSearchLinks";
import KeywordSearches from "../components/KeywordSearches";
import { useGetAcademicLeadJobsQuery } from "../store/apiSlice";
import AcademicLeadJobs from "../components/AcademicJobLeads";

function AdminHome(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [employerID, setEmployerID] = useState(3173)
  const region = useSelector((state) => state.posts.region);
  const userInfo = useSelector((state) => state.auth.userInfo);
  const { data, isLoading, isSuccess } = useGetJobPaddingEmployersQuery();
  const { data: userData } = useGetUserByIdQuery({ id: userInfo.id });
  const { data: AuNzEmployers } = useGetAUNZEmployersQuery({ id: userInfo.id });
  const { data: academicLeadsJobs } = useGetAcademicLeadJobsQuery();

  let content, content1, AUNZEmployers1, unlimitedClients, academicLeads, keyWordSearches;
  console.log(userInfo)
  if (isLoading) {
    content1 = <p>Loading...</p>;
  } else if (isSuccess) {
    console.log("data0430", data)
    content1 = (
      <div className="bg-slate-200 p-6 rounded-xl mb-8 text-gray-600 font-bold shadow-black shadow-sm">
        <h1>Job Padding Required</h1>
        <div className="container mx-auto py-8">
          <TopUniversityPadding data={data} />
        </div>
      </div>
    )
  }
  if (userInfo.id === 77) {
    content = <div className="w-full px-6 gap-8 ">
      {content1}
    </div>
    AUNZEmployers1 =
      <div className="w-[66%] bg-slate-200 p-6 rounded-xl mb-8 mr-6 ml-4 shadow-black shadow-sm">
        <h1 className="text-gray-600 font-bold p-3">AUST/NZ Job Padding (with sales leads)</h1>
        <AusNzEmployers data={AuNzEmployers} />
      </div>
    unlimitedClients =
      <div>
        <div className=" bg-slate-200 p-6 rounded-xl mb-8 mr-6 ml-4 shadow-black shadow-sm">
          <h1 className="text-gray-600 font-bold p-3">Unlimited Clients</h1>
          <UnlimitedClients data={AuNzEmployers} />
        </div>
      </div>
  } else {
    content = <div className=" bg-slate-200 p-6 rounded-xl mb-8 mr-6 ml-4 shadow-black shadow-sm">
      <h1 className=" text-gray-600 font-bold mb-4">Headline Job Loading (with Sales Leads)</h1>
      <p className="pb-4" > Instructions: Please load 5 jobs per university/college, adding in "sales leads" whenever there is a sales lead.
        Also add the number of jobs on their job page URL. Note: All this data will be used in assesing our approach to all prospective clients in the future.
        NEVER load internal jobs.
      </p>
      {userData &&
        <LowJobCountEmlpoyers data={userData.institutionList} />}
    </div>
  }

  if (userInfo.id === 160) {
    content = <div className=" bg-slate-200 p-6 rounded-xl mb-8 mr-6 ml-8 shadow-black shadow-sm">
    <h1 className="text-gray-600 font-bold p-3">Academic Leads Required</h1>
    <AcademicLeadJobs jobs={academicLeadsJobs} />
  </div>
  }

  return (
    <div>
      <h1 className="text-3xl font-bold text-black shadow-xl px-2 pb-4 mt-4 mb-6 ml-6">
        Welcome {userInfo.firstName} !</h1>
      <div className="flex">
        <div className="w-1/2">
          {content}
        </div>
        {AUNZEmployers1}
      </div>
      <div className="flex">
        {unlimitedClients}
        <div className="w-1/2  gap-8 ">
          <div className=" bg-slate-200 p-6 rounded-xl mb-8 mr-6 ml-8 shadow-black shadow-sm">

            <h1 className="text-gray-600 font-bold p-3">Google search Links (Keyword Searches)</h1>

            <p className="font-bold text-red-500 mb-4">IMPORTANT TASK FOR ALL:  go to google, search for 'Research Assistant Jobs Melbourne' which will be on the second page, stay on those pages for a while and keep it open while you do other tasks. When possible, please do the same for other states: Canberra, Sydney, Brisbane, Perth & Adelaide! replacing the city name with those cities in your search</p>
            <ul className="list-decimal font-bold ml-8 mb-8">
              <li>With your VPN, click the links below</li>
              <li>Then you scroll down to our Academic Jobs website, click on our link and make sure you stay on our site for 2-5 minutes. NO LESS.</li>
              <li>IMPORTANT: do NOT click the "sponsored" link to Academic Jobs- this will cost us money and should be avoided</li>
            </ul>
            <KeywordSearches userId={userInfo.id} />
            <h1 className="text-gray-600 font-bold p-3">Extra tasks:</h1>
            <ul className="list-decimal font-bold ml-8 mb-8">
              <li>Set VPN to australia</li>
              <li>Go to google search. Search for 'How to become university lecturer'</li>
              <li>Click on academicjob's page: 'Become University Lecturer in Australia: Earn 115k'</li>
              <li>Scroll through and stay on the main page for 5 minutes. After 5 minutes, engage with the page by clicking on the links. </li>
            </ul>


            <ul className="list-decimal font-bold ml-8 mb-8">
              <li>Set VPN to australia</li>
              <li>Go to google and search “Postdoc Success”</li>
              <li>FInd the page for Academic jobs. Postdoc success: How to thrive in your research role</li>
              <li>Stay on the page for at least five minutes. Then, click on the links as necessary to other blog pages. </li>
            </ul>
          </div>
        </div>
        <div className="w-1/2 bg-slate-200 p-6 rounded-xl mb-8 mr-6 ml-4 shadow-black shadow-sm">
          <div className=" w-full bg-slate-200 p-2 rounded-xl ">
            <h1 className=" font-bold mb-2 text-gray-600">Team Notes</h1>
            <div className="rounded-xl w-full custom-scrollbar max-h-[200px] overflow-y-auto border whitespace-pre-wrap bg-white p-3 mb-4">
              {userData &&
                <div dangerouslySetInnerHTML={{ __html: userData.task.task }} />}
            </div>
          </div>
          <h1 className=" text-gray-600 font-bold">Manila Team</h1>
          <div className="flex gap-4 mb-6">
            <div className="grid grid-cols-4 gap-5 mt-4">
              <figure className="    rounded-3xl ">
                <img
                  width={150}
                  height={150}
                  src="https://academicjobs.s3.amazonaws.com/img/_misc/420242991_741144318123644_4167791228012772159_n.jpg"
                  alt="How to Land Your Dream College Job: A Step-by-Step Guide"
                  className="rounded-3xl shadow-black shadow-sm" />
                <h2 className="  capitalize font-bold pb-1">
                  {' '}
                  The Don
                </h2>
              </figure>
              <figure className="    rounded-3xl ">
                <img
                  width={150}
                  height={150}
                  src="https://academicjobs.s3.amazonaws.com/img/users/laira_andrea_austria.jpg"
                  alt="How to Land Your Dream College Job: A Step-by-Step Guide"
                  className="rounded-3xl shadow-black shadow-sm" />
                <h2 className="  capitalize font-bold pb-1 ">
                  {' '}
                  laira andrea austria
                </h2>
              </figure>
              <figure className="   rounded-3xl ">
                <img
                  width={150}
                  height={150}
                  src="https://academicjobs.s3.amazonaws.com/img/users/joan_santos.jpg"
                  alt="How to Land Your Dream College Job: A Step-by-Step Guide"
                  className="rounded-3xl h-4/5 w-40 shadow-black shadow-sm" />
                <h2 className="  capitalize font-bold pb-1">
                  {' '}
                  joan santos
                </h2>
              </figure>
              <figure className="   rounded-3xl">
                <img
                  width={150}
                  height={150}
                  src="https://academicjobs.s3.amazonaws.com/img/users/lexphil_de_vera.jpg"
                  alt="How to Land Your Dream College Job: A Step-by-Step Guide"
                  className="rounded-3xl shadow-black shadow-sm" />
                <h2 className="  capitalize font-bold pb-1">
                  {' '}
                  lexphil de vera
                </h2>
              </figure>
              <figure className="   rounded-3xl ">
                <img
                  width={150}
                  height={150}
                  src="https://academicjobs.s3.amazonaws.com/img/users/cai_portrait.jpg"
                  alt="How to Land Your Dream College Job: A Step-by-Step Guide"
                  className="rounded-3xl shadow-black shadow-sm" />
                <h2 className="  capitalize font-bold pb-1">
                  {' '}
                  Maria Luisa Violeta
                </h2>
              </figure>
              <figure className="    rounded-3xl ">
                <img
                  width={150}
                  height={150}
                  src="https://academicjobs.s3.amazonaws.com/img/users/pauline_aura.jpg"
                  alt="How to Land Your Dream College Job: A Step-by-Step Guide"
                  className="rounded-3xl shadow-black shadow-sm" />
                <h2 className="  capitalize font-bold pb-1">
                  {' '}
                  Pauline
                </h2>
              </figure>
              <figure className="   rounded-3xl">
                <img
                  width={150}
                  height={150}
                  src="/John-michael-cruz.png"
                  alt="How to Land Your Dream College Job: A Step-by-Step Guide"
                  className="rounded-3xl shadow-black shadow-sm" />
                <h2 className="  capitalize font-bold pb-1">
                  {' '}
                  jhon michael cruz
                </h2>
              </figure>
              <figure className="    rounded-3xl ">
                <img
                  width={150}
                  height={130}
                  src="https://academicjobs.s3.amazonaws.com/img/users/mary_rose_joana_pecson.jpg"
                  alt="How to Land Your Dream College Job: A Step-by-Step Guide"
                  className="rounded-3xl shadow-black shadow-sm" />
                <h2 className="  capitalize font-bold pb-1">
                  {' '}
                  mary rose joana pecson
                </h2>
              </figure>
              <figure className="   rounded-3xl">
                <img
                  width={150}
                  height={150}
                  src="https://academicjobs.s3.amazonaws.com/img/users/ClarissaPacetes.png"
                  alt="How to Land Your Dream College Job: A Step-by-Step Guide"
                  className="rounded-3xl shadow-black shadow-sm" />
                <h2 className="  capitalize font-bold pb-1">
                  {' '}
                  Clarissa Pacetes
                </h2>
              </figure>
            </div>
          </div>
          <h1 className=" text-gray-600 font-bold">Melbourne Team</h1>
          <div className="flex gap-6">
            <div className="grid grid-cols-4 gap-5 mt-4">
              <figure className="rounded-3xl ">
                <img
                  width={150}
                  height={150}
                  src="/Jarrod.png"
                  alt="How to Land Your Dream College Job: A Step-by-Step Guide"
                  className="rounded-3xl shadow-black shadow-sm" />
                <h2 className="  capitalize font-bold pb-1">
                  {' '}
                  Jarrod
                </h2>
              </figure>
              <figure className="rounded-3xl ">
                <img
                  width={150}
                  height={150}
                  src="/I-letter.png"
                  alt="How to Land Your Dream College Job: A Step-by-Step Guide"
                  className="rounded-3xl shadow-black shadow-sm" />
                <h2 className="  capitalize font-bold pb-1">
                  {' '}
                  Isabella
                </h2>
              </figure>
              <figure className="rounded-3xl ">
                <img
                  width={150}
                  height={150}
                  src="/L-letter.png"
                  alt="How to Land Your Dream College Job: A Step-by-Step Guide"
                  className="rounded-3xl shadow-black shadow-sm" />
                <h2 className="  capitalize font-bold pb-1">
                  {' '}
                  Leanne
                </h2>
              </figure>
              <figure className="rounded-3xl ">
                <img
                  width={150}
                  height={150}
                  src="/W-letter.png"
                  alt="How to Land Your Dream College Job: A Step-by-Step Guide"
                  className="rounded-3xl shadow-black shadow-sm" />
                <h2 className="  capitalize font-bold pb-1">
                  {' '}
                  Walter
                </h2>
              </figure>
              <figure className="rounded-3xl ">
                <img
                  width={150}
                  height={150}
                  src="/C-letter.png"
                  alt="How to Land Your Dream College Job: A Step-by-Step Guide"
                  className="rounded-3xl shadow-black shadow-sm" />
                <h2 className="  capitalize font-bold pb-1">
                  {' '}
                  Cian
                </h2>
              </figure>

            </div>

          </div>


        </div>
      </div>
    </div >
  );
}
export default AdminHome;
