import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import RegionalPosting from './RegionalPosting';
import { useDeductJobCreditsMutation, useUpdateJobMutation } from "../store/apiSlice";
import CreateInvoiceNicheJobBoardPayment from '../components/CreateInvoiceNicheJobBoardPayment';
import CountryList from '../data/CountryList.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

const JobList1 = ({ endOfJobs, data, jobBoardData, nextPage }) => {
  const navigate = useNavigate();
  const [showActivationModal, setShowActivationModal] = useState(false);
  const [showRegionalPostingModal, setShowRegionalPostingModal] = useState(false);
  const [showJobBoardModal, setShowJobBoardModal] = useState(false);
  const [jobToActivate, setJobToActivate] = useState(null);
  const [jobForRegionalPosting, setJobForRegionalPosting] = useState(null);
  const [selectedJobBoard, setSelectedJobBoard] = useState(null);
  const [selectedJobForBoard, setSelectedJobForBoard] = useState(null); // Track job for job board modal
  const [deductJobCredit] = useDeductJobCreditsMutation();
  const [updateJob] = useUpdateJobMutation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [expandedJob, setExpandedJob] = useState(null); // Manage expanded state per job

  const mapCountryCodesToNames = (codes) => {
    return codes.map(code => {
      const country = CountryList.find(c => c.code === code);
      return country ? country.name : code; // Return the name or the code if not found
    });
  };

  const handleEditJob = (job) => {
    navigate("/post-job-employer/", { state: { job } });
  };

  const userInfo = useSelector((state) => state.auth.userInfo);
  const jobPostCreditCount = useSelector((state) => state.posts.jobCredits);

  const navigateToPaymentPage = () => {
    navigate('/choose-job-pack');
  };

  const openActivationModal = (job) => {
    setShowActivationModal(true);
    setJobToActivate(job);  // Only set for activation modal
    setExpandedJob(null); // Close expanded view after selection
  };

  const openRegionalPostingModal = (job) => {
    setShowRegionalPostingModal(true);
    setJobForRegionalPosting(job);
    setExpandedJob(null); // Close expanded view after selection
  };

  const openJobBoardModal = (job) => {
    setShowJobBoardModal(true);
    setSelectedJobForBoard(job);  // Set job for job board modal
    setExpandedJob(null); // Close expanded view after selection
  };

  const handleButtonClick = (job) => {
    if (jobPostCreditCount === 0) {
      navigateToPaymentPage();
    } else {
      openActivationModal(job);
    }
  };

  const handleCloseRegionalPostingModal = () => {
    setShowRegionalPostingModal(false);
    setJobForRegionalPosting(null);
  };

  const handleCloseJobBoardModal = () => {
    setShowJobBoardModal(false);
    setSelectedJobBoard(null);
    setSelectedJobForBoard(null);
  };

  const handleCloseActivationModal = () => {
    setShowActivationModal(false);
    setJobToActivate(null);
  };

  const toggleJobExpansion = (jobId) => {
    setExpandedJob((prev) => (prev === jobId ? null : jobId));  // Toggle specific job's expansion
  };

  const handleConfirmActivation = async (job) => {
    setLoading(true);
    setError(null);

    try {
      await deductJobCredit({ userId: userInfo.id }).unwrap();
      await updateJob({ id: job.id, headlineOnly: false });
      handleCloseActivationModal();
    } catch (error) {
      setError('There was an error activating the job. Please try again.');
      console.error('Error during job activation:', error);
    } finally {
      setLoading(false);
    }
    window.location.reload();
  };

  const content = data.map((job, index) => {
    const {
      headlineOnly,
      email1,
      activation_date,
      createdAt,
      activeYN,
      employer_id,
      title,
      location,
      expiration_date,
      how_to_apply,
      logo,
      company_name,
      featured,
      id,
      description,
      salary_from,
      salary_to,
      postRegions
    } = job;

    const currentDate = new Date();
    const expirationDate = new Date(expiration_date);

    return (
      <div className={`bg-white border mt-2 relative border-gray-200 p-4 mb-8 rounded-xl shadow-lg block font-bold`} key={index}>
        <div className="flex justify-between">
          <div className="flex items-center pb-2">
            <div className="flex-1">
              <span className="">
                <a className="text-gray-500 text-xl mr-4 font-bold leading-tight hover:underline cursor-pointer" href={`https://www.academicjobs.com/jobs/myjob/${id}`} target="_blank">
                  {title}
                </a>
              </span>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center gap-1.5 mx-auto md:mx-0 mb-2">
            {new Date(expiration_date) > new Date() && expiration_date && headlineOnly ? (
              <button
                className="btn-sm btn-accent rounded-full leading-tight"
                onClick={() => handleButtonClick(job)}
              >
                Activate Full Ad Now
              </button>
            ) : null}
          </div>
        </div>
        <div className="flex justify-between items-start mb-2">
          {/* <div>
            <button
              className="text-grey-500 text-sm mt-8"
              onClick={() => toggleJobExpansion(id)}
            >
              <i className="fas fa-cog mr-1"></i>
              {expandedJob === id ? "Hide Actions" : "Manage"}
            </button>
          </div> */}

          <div className="flex text-gray-700 text-sm font-light flex-col">
            {headlineOnly ? (
              <span>'Headline Job' Only</span>
            ) : (
              <span className="text-xs text-emerald-700 font-semibold rounded-full mr-auto">
                Full Job
              </span>
            )}
            <time>
              Posted:{" "}
              <span className="text-gray-400">
                {new Date(activation_date).toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                })}
              </span>
            </time>
            {expiration_date && (
              <time>
                Exp.{" "}
                <span className="text-gray-400">
                  {expiration_date &&
                    `${new Date(expiration_date).toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    })}`}
                </span>
              </time>
            )}

          </div>
          
        </div>

              <div className="border-t">
                <div className="mt-4 flex items-center gap-2 flex-wrap sm:flex-nowrap">
                {!headlineOnly && expirationDate > currentDate && (
                  <div className="w-full sm:w-auto">
                    <button
                      className="btn text-sky-600 hover:underline text-xs cursor-pointer w-full sm:w-auto"
                      onClick={() => handleEditJob(job)}
                    >
                      Edit Job
                    </button>
                  </div>
                )}
                <div className="w-full sm:w-auto">
                  <a
                    className="btn text-amber-500 hover:underline text-xs cursor-pointer w-full sm:w-auto"
                    href={`https://www.academicjobs.com/jobs/myjob/${id}?mode=share`}
                    target="_blank"
                  >
                    Academic Connect
                  </a>
                </div>
                <div className="w-full sm:w-auto">
                  <a
                    className="btn text-xs px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => openRegionalPostingModal(job)}
                  >
                    Post this job internationally
                  </a>
                </div>
                <div className="w-full sm:w-auto">
                  <a
                    className="btn text-xs px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => openJobBoardModal(job)}
                  >
                    Post on top niche job boards
                  </a>
                </div>
                </div>
              </div>

        {expirationDate < currentDate && expiration_date ? (
          <div className="absolute text-xs bg-red-700 text-white font-semibold rounded-full px-3 pt-[3px] pb-[3px] top-[-10px] z-10 right-3">Expired</div>
        ) : expirationDate > currentDate && featured ? (
          <div className="text-xs bg-emerald-500 font-semibold rounded-full px-2 py-[1px] text-white absolute top-[-8px] z-10 right-3">
            <span>Featured</span>
          </div>
        ) : null}
      </div>
    );
  });

  return (
    <div className="overflow-y w-full">
      {content}
      {/* Modals and other UI elements */}
      {showActivationModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
            <h2 className="text-xl font-bold mb-4">Confirm Activation</h2>
            <p className="mb-4">Are you sure you want to activate this job post? This will require 1 job credit.</p>
            {error && <div className="text-red-500 mb-4">{error}</div>}
            <div className="flex justify-end space-x-4">
              <button className="btn btn-primary" onClick={() => handleConfirmActivation(jobToActivate)} disabled={loading}>
                {loading ? 'Processing...' : 'Yes'}
              </button>
              <button className="btn " onClick={handleCloseActivationModal}>Cancel</button>
            </div>
          </div>
        </div>
      )}
      {showRegionalPostingModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
            <h2 className="text-xl font-bold mb-4">International Posting</h2>
            <RegionalPosting postRegions={jobForRegionalPosting.postRegions} id={jobForRegionalPosting.id} />
            <div className="flex justify-end space-x-4">
              <button className="btn" onClick={handleCloseRegionalPostingModal}>Close</button>
            </div>
          </div>
        </div>
      )}
      {showJobBoardModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg h-full sm:h-auto overflow-y-auto relative">
            <button className="absolute top-0 right-0 p-2 text-gray-500 hover:text-gray-800" onClick={handleCloseJobBoardModal}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <h2 className="text-xl font-bold mb-4">Post on Top Job Boards</h2>
            <p className="mb-4">Please select a job board for posting the job:</p>
            <select
              className="form-select w-full mb-4 p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              onChange={(e) => setSelectedJobBoard(jobBoardData.find(jb => jb.id === parseInt(e.target.value)))}
              defaultValue=""
            >
              <option value="" disabled className="text-gray-500">Select Job Board</option>
              {jobBoardData.map((board) => (
                <option key={board.id} value={board.id} className="text-sm text-gray-800">
                  <span className="font-bold">{board.name}</span>
                </option>
              ))}
            </select>

            {selectedJobBoard && (
              <>
                <p className="mb-4">
                  <strong>Selected Job Board:</strong>
                  <a target="_blank" href={selectedJobBoard.url} style={{ textDecoration: 'none' }}>
                    {selectedJobBoard.name}
                    <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginLeft: '5px' }} />
                  </a>
                  <br />

                  {selectedJobBoard.servicedCountries && selectedJobBoard.servicedCountries.length > 0 && (
                    <>
                      <strong>Serviced Countries:</strong> {mapCountryCodesToNames(selectedJobBoard.servicedCountries).join(', ')}<br />
                    </>
                  )}
                  <strong>Traffic:</strong> {selectedJobBoard.monthlyTraffic ? selectedJobBoard.monthlyTraffic.toLocaleString('en-US') : 'N/A'} users per month<br />
                  <strong>Ranking:</strong> {selectedJobBoard.ranking}/10<br />
                  <strong>Price:</strong> ${selectedJobBoard.jobAdPrice} (AUD - ex GST)
                </p>
                <h2 className="text-xl font-bold mb-4">Payment details</h2>
                <CreateInvoiceNicheJobBoardPayment employer={userInfo} job={selectedJobForBoard} jobBoard={selectedJobBoard} />
              </>
            )}
            {error && <div className="text-red-500 mb-4">{error}</div>}
          </div>
        </div>
      )}
    </div>
  );
};

export default JobList1;
