import React, { useState } from "react";
import DashboardCard from "./profile/DashboardCards";
import { useDispatch } from "react-redux";
import { useNavigate, Link, NavLink } from "react-router-dom";
import { useSendLogoutMutation } from "../views/auth/authApiSlice";
import { logOut } from "../views/auth/authSlice"

const DashboardSection = ({ userInfo }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [sendLogout] = useSendLogoutMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleMenuItemClick = () => {
    // Close the dropdown when a menu item is clicked
    setDropdownOpen(false);
  };

  return (
    <section title="Quick Links (Shortcuts)" className="mt-8">
      <div className="md:hidden">
        <button
          onClick={() => setDropdownOpen(!dropdownOpen)}
          className="w-full bg-gray-200 text-gray-800 font-semibold py-2 px-4 rounded"
        >
          Menu
          <span className={`ml-2 transform ${dropdownOpen ? 'rotate-180' : 'rotate-0'}`}>
            ▼
          </span>
        </button>
        {dropdownOpen && (
          <div className="flex flex-col mt-2">
            <DashboardCard
              title="Dashboard"
              iconPath="/icons/dashboard.png"
              href={`/dashboard`}
              onClick={handleMenuItemClick} // Close the dropdown when clicked
            />
            <DashboardCard
              title="University Profile"
              iconPath="/icons/uniprofile.png"
              href={`/university-profile`}
              onClick={handleMenuItemClick} // Close the dropdown when clicked
            />
            <DashboardCard
              title="Current Posted Jobs"
              iconPath="/icons/currentjobs.png"
              href={`/jobs-center1`}
              onClick={handleMenuItemClick} // Close the dropdown when clicked
            />
            <DashboardCard
              title="Post a Job"
              iconPath="/icons/postajob.png"
              href={`/post-job-employer`}
              onClick={handleMenuItemClick} // Close the dropdown when clicked
            />
            <DashboardCard
              title="Academic Connect"
              iconPath="/network-icon.svg"
              href={`/academic-connect`}
            />
            <DashboardCard
              title="Logout"
              iconPath="/logout-svgrepo-com.svg"
              onClick={() => {
                sendLogout();
                document.cookie = 'jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
                dispatch(logOut())
                navigate("/");
                window.location.reload();
              }}
            />
          </div>
        )}
      </div>
      <div className="hidden md:flex gap-1">
        <DashboardCard
          title="Dashboard"
          iconPath="/icons/dashboard.png"
          href={`/dashboard`}
        />
        <DashboardCard
          title="University Profile"
          iconPath="/icons/uniprofile.png"
          href={`/university-profile`}
        />
        <DashboardCard
          title="Current Posted Jobs"
          iconPath="/icons/currentjobs.png"
          href={`/jobs-center1`}
        />
        <DashboardCard
          title="Post a Job"
          iconPath="/icons/postajob.png"
          href={`/post-job-employer`}
        />
        <DashboardCard
          title="Academic Connect"
          subTitle="Our unique referral program"
          iconPath="/network-icon.svg"
          href={`/academic-connect`}
        />
      </div>
    </section>
  );
};

export default DashboardSection;
