import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import InputBlock5 from './InputBlock5';
import { useUpdateContactMutation } from "../store/apiSlice";

const OtherContactsRowEdit = ({ contact, onClose }) => {
  const [updateContact] = useUpdateContactMutation();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset(contact);
  }, [contact, reset]);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    await updateContact({ id: contact?.id, ...data });
    onClose();
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-4">
      <div className="flex justify-between">
      <h2 className="text-xl font-semibold mb-3">Contact details</h2>
      <button
            type="button"
            className="btn btn-xs"
            onClick={onClose}
          >
            X
      </button>
      </div>

      <form className="grid grid-cols-1 gap-4" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <InputBlock5
            type="text"
            field="firstName"
            label="First Name"
            register={register}
            errors={errors}
          />
        </div>
        <div>
          <InputBlock5
            type="text"
            field="lastName"
            label="Last Name"
            register={register}
            errors={errors}
          />
        </div>
        <div>
          <InputBlock5
            type="text"
            field="title"
            label="Title"
            register={register}
            errors={errors}
          />
        </div>
        <div>
          <InputBlock5
            type="text"
            field="email"
            label="Email"
            register={register}
            errors={errors}
          />
        </div>
        <div>
          <InputBlock5
            type="text"
            field="phone"
            label="Phone"
            register={register}
            errors={errors}
          />
        </div>
        <div className="relative">
          <span> HR </span>
          <input type="checkbox" {...register("hrYN")} />
        </div>
        <div>
          <span>Recruiter </span>
          <input type="checkbox" {...register("recruiterYN")} />
        </div>
        <div>
          <span>Department Lead </span>
          <input type="checkbox" {...register("departmentLeadYN")} />
        </div>
        <div className="flex justify-between">
          <button
            type="button"
            className="text-black underline text-sm"
            onClick={(e) => {
              if (
                window.confirm(
                  "Are you sure you want to delete this contact? This action cannot be undone."
                )
              ) {
                onSubmit({ delete: true }, e);
              }
            }}
          >
            Delete
          </button>
          <button type="submit" className="btn btn-xs">Save</button>

        </div>
      </form>
    </div>
  );
};

export default OtherContactsRowEdit;
