import React, { useState, useEffect } from "react";
import { useUpdatePostRegionsMutation } from "../store/apiSlice";

const RegionalPosting = ({ postRegions, id }) => {
  const [updatePostRegions] = useUpdatePostRegionsMutation();
  const [selectedRegions, setSelectedRegions] = useState(postRegions || []);

  useEffect(() => {
    setSelectedRegions(postRegions || []);
  }, [postRegions]);

  const handleCheckboxChange = (value) => {
    const updatedRegions = selectedRegions.includes(value)
      ? selectedRegions.filter(region => region !== value) // Remove if already selected
      : [...selectedRegions, value]; // Add if not selected
    setSelectedRegions(updatedRegions);
    updatePostRegions({ id, postRegions: updatedRegions });
  };

  const options = [
    { value: "Canada", label: "Canada" },
    { value: "New Zealand", label: "New Zealand" },
    { value: "United Kingdom", label: "United Kingdom" },
    { value: "United States", label: "United States" },
  ];

  return (
    <div className="flex flex-col gap-0 mb-3">
      <div className="text-sm mb-4">Select regions to post this job as an International Opportunity Banner:</div>
      <div className="flex flex-wrap gap-4">
        {options.map(option => (
          <div key={option.value} className="flex items-center">
            <input
              type="checkbox"
              checked={selectedRegions.includes(option.value)}
              onChange={() => handleCheckboxChange(option.value)}
              className="mr-1"
            />
            <span>{option.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RegionalPosting;
